import {
  actionCreator,
  GET_DEFAULT_VARIABLES_ERROR,
  GET_DEFAULT_VARIABLES_REQUEST,
  TError,
  TDefaultVariablesPayload,
  GET_DEFAULT_VARIABLES_SUCCESS,
  UPDATE_DEFAULT_VARIABLES_REQUEST,
  UPDATE_DEFAULT_VARIABLES_SUCCESS,
  UPDATE_DEFAULT_VARIABLES_ERROR,
  UPDATE_REGISTRATION_MESSAGES_REQUEST,
  UPDATE_REGISTRATION_MESSAGES_SUCCESS,
  UPDATE_REGISTRATION_MESSAGES_ERROR,
  GET_REGISTRATION_MESSAGES_REQUEST,
  GET_REGISTRATION_MESSAGES_SUCCESS,
  GET_REGISTRATION_MESSAGES_ERROR,
  TUpdateDefaultVariablesPayload,
  TUpdateRegistrationMessagesPayload
} from 'store'

export const getDefaultVariablesRequest = () => actionCreator(GET_DEFAULT_VARIABLES_REQUEST)
export const getDefaultVariablesSuccess = (payload: TDefaultVariablesPayload) =>
  actionCreator(GET_DEFAULT_VARIABLES_SUCCESS, payload)
export const getDefaultVariablesError = (e: TError) => actionCreator(GET_DEFAULT_VARIABLES_ERROR, e)

export const updateDefaultVariablesRequest = (payload: TUpdateDefaultVariablesPayload) =>
  actionCreator(UPDATE_DEFAULT_VARIABLES_REQUEST, payload)
export const updateDefaultVariablesSuccess = () => actionCreator(UPDATE_DEFAULT_VARIABLES_SUCCESS)
export const updateDefaultVariablesError = (e: TError) =>
  actionCreator(UPDATE_DEFAULT_VARIABLES_ERROR, e)

export const updateRegistrationMessagesRequest = (payload: TUpdateRegistrationMessagesPayload) =>
  actionCreator(UPDATE_REGISTRATION_MESSAGES_REQUEST, payload)
export const updateRegistrationMessagesSuccess = () =>
  actionCreator(UPDATE_REGISTRATION_MESSAGES_SUCCESS)
export const updateRegistrationMessagesError = (e: TError) =>
  actionCreator(UPDATE_REGISTRATION_MESSAGES_ERROR, e)

export const getRegistrationMessagesRequest = () => actionCreator(GET_REGISTRATION_MESSAGES_REQUEST)
export const getRegistrationMessagesSuccess = (payload: TUpdateRegistrationMessagesPayload) =>
  actionCreator(GET_REGISTRATION_MESSAGES_SUCCESS, payload)
export const getRegistrationMessagesError = (e: TError) =>
  actionCreator(GET_REGISTRATION_MESSAGES_ERROR, e)
