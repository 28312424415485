import React, { FC, useCallback, useMemo } from 'react'
import { Card, Row, Col, Typography, Form, InputNumber, Checkbox } from 'antd'
import { useParams } from 'react-router-dom'
import moment from 'moment'
import { groupBy } from 'lodash'
import { AccountTypeIdEnum } from '@medentee/enums'

import { useAdaptiveLayout, useAppDispatch } from 'App/hooks'
import { TUserProfilePersonal, UPDATE_USER_PROFILE, updateUserProfileRequest } from 'store'
import { longDateTime } from 'utils'
import { DefaultFormCard } from 'App/common'

import { MedCloudInfo, CasesInfo, UsersList } from '../index'

import styles from './UserProfilePersonal.module.scss'

export type TFormValuesBusinessAccounts = {
  allowCreateBusinessAccounts: boolean
}

export type TFormValuesParticipations = {
  maxParticipationsAsAdmin: number
  maxParticipationsAsSuperadmin: number
}

export type TUserProfilePersonalProps = {
  data: TUserProfilePersonal
}

export const UserProfilePersonal: FC<TUserProfilePersonalProps> = (props) => {
  const { data } = props
  const dispatch = useAppDispatch()
  const { isMobile } = useAdaptiveLayout()
  const { id } = useParams<{ id: string }>()
  const isActive = id === data.userId

  const handleUpdateProfile = useCallback(
    (values: TFormValuesBusinessAccounts | TFormValuesParticipations) =>
      dispatch(updateUserProfileRequest({ id, ...values })),
    [id, dispatch]
  )

  const groupedChildren = useMemo(() => groupBy(data.children, 'typeId'), [data.children])

  if (!isActive || !('professionInfo' in data)) {
    return null
  }

  const professionalHeader = (
    <Col xs={24} sm={12}>
      <dl>
        <dt>
          <b>Descendants:</b>
        </dt>
        <dd>{data.childrenCount}</dd>
        <dt>Invited new Professionals (registered):</dt>
        <dd>{groupedChildren['1']?.length ?? 0}</dd>
      </dl>
    </Col>
  )

  const ancestorsHeader = (
    <Col xs={24} sm={12}>
      <dl>
        <dt>
          <b>Generation:</b>
        </dt>
        <dd>{data.generation}</dd>
      </dl>
      <Typography.Text>Ancestors</Typography.Text>
    </Col>
  )

  return (
    <Row gutter={[24, 24]}>
      <Col md={12} xs={24}>
        <Card bordered={false}>
          <dl>
            <dt>Email:</dt>
            <dd>{data.email}</dd>
            <dt>Profession:</dt>
            <dd>{data.professionInfo.name}</dd>
            <dt>Specialization(s):</dt>
            <dd>
              {data.professionInfo.specializations.length
                ? data.professionInfo.specializations.join(', ')
                : '-'}
            </dd>
            <dt>Location:</dt>
            <dd>
              {data.location.cityName}, {data.location.countryName}
            </dd>
            <dt>Registration date:</dt>
            <dd>{moment(data.createdAt).format(longDateTime)}</dd>
          </dl>
        </Card>
      </Col>
      <Col xs={24} sm={12} md={6}>
        <MedCloudInfo data={data.medCloud} />
      </Col>
      <Col xs={24} sm={12} md={6}>
        <CasesInfo data={data.cases} />
      </Col>

      <Col xs={24}>
        <DefaultFormCard
          title="Account Limits"
          onFormSubmit={handleUpdateProfile}
          initialValues={{
            maxParticipationsAsAdmin: data.maxParticipationsAsAdmin,
            maxParticipationsAsSuperadmin: data.maxParticipationsAsSuperadmin
          }}
          loadingActionType={UPDATE_USER_PROFILE}
        >
          {(disabled) => (
            <Row gutter={[20, 20]} justify="space-between">
              <Col xs={12}>
                <Form.Item
                  name="maxParticipationsAsSuperadmin"
                  label="Superadmin limit"
                  rules={[
                    { required: true, message: 'This field is required' },
                    { type: 'number', min: 0, max: 100, message: 'Value must be between 0 and 100' }
                  ]}
                >
                  <InputNumber min={0} max={100} style={{ width: '100%' }} disabled={disabled} />
                </Form.Item>
              </Col>
              <Col xs={12}>
                <Form.Item
                  name="maxParticipationsAsAdmin"
                  label="Admin limit"
                  rules={[
                    { required: true, message: 'This field is required' },
                    { type: 'number', min: 0, max: 100, message: 'Value must be between 0 and 100' }
                  ]}
                >
                  <InputNumber min={0} max={100} style={{ width: '100%' }} disabled={disabled} />
                </Form.Item>
              </Col>
            </Row>
          )}
        </DefaultFormCard>
      </Col>

      <Col md={12} xs={24}>
        <Card bordered={false}>
          <Row gutter={[20, 20]} justify="end">
            {!isMobile && (
              <>
                {professionalHeader}
                {ancestorsHeader}
              </>
            )}
            {isMobile && professionalHeader}
            <Col xs={24} sm={12}>
              <UsersList data={groupedChildren[AccountTypeIdEnum.PROFESSIONAL]} />
            </Col>
            {isMobile && ancestorsHeader}
            <Col xs={24} sm={12}>
              <UsersList data={data.parents} />
            </Col>
          </Row>
        </Card>
      </Col>

      <Col md={12} xs={24}>
        <Row gutter={[20, 20]}>
          <Col xs={24}>
            <Card bordered={false}>
              <Row gutter={[20, 20]} justify="space-between">
                <Col xs={24} sm={12}>
                  <dl>
                    <dt>
                      <b>Associates:</b>
                    </dt>
                    <dd>{data.partners.professional + data.partners.business}</dd>
                    <dt>professionals:</dt>
                    <dd>{data.partners.professional}</dd>
                    <dt>business:</dt>
                    <dd>{data.partners.business}</dd>
                  </dl>
                </Col>
              </Row>
            </Card>
          </Col>
          <Col xs={24}>
            <DefaultFormCard
              onFormSubmit={handleUpdateProfile}
              title="Business Accounts"
              initialValues={{
                allowCreateBusinessAccounts: data.allowCreateBusinessAccounts
              }}
              loadingActionType={UPDATE_USER_PROFILE}
            >
              {(disabled) => (
                <Row gutter={[20, 20]} justify="space-between">
                  <Col xs={24}>
                    <Form.Item valuePropName="checked" name="allowCreateBusinessAccounts">
                      <Checkbox disabled={disabled}>Allow to Create Business Account</Checkbox>
                    </Form.Item>
                  </Col>
                  <Col xs={24}>
                    <dl>
                      <dt>
                        <b>Related business accounts:</b>
                      </dt>
                      <dd>
                        {data.businessAccounts.admin.length +
                          data.businessAccounts.superadmin.length}
                      </dd>
                    </dl>
                  </Col>
                  <Col xs={12}>
                    <Typography.Text className={styles.title}>
                      Superadmin: {data.businessAccounts.superadmin.length}
                    </Typography.Text>
                    <UsersList data={data.businessAccounts.superadmin} />
                  </Col>
                  <Col xs={12}>
                    <Typography.Text className={styles.title}>
                      Admin: {data.businessAccounts.admin.length}
                    </Typography.Text>
                    <UsersList data={data.businessAccounts.admin} />
                  </Col>
                </Row>
              )}
            </DefaultFormCard>
          </Col>
        </Row>
      </Col>
    </Row>
  )
}
