export const GET_DEFAULT_VARIABLES = 'GET_DEFAULT_VARIABLES'
export const GET_DEFAULT_VARIABLES_REQUEST = 'GET_DEFAULT_VARIABLES_REQUEST'
export const GET_DEFAULT_VARIABLES_SUCCESS = 'GET_DEFAULT_VARIABLES_SUCCESS'
export const GET_DEFAULT_VARIABLES_ERROR = 'GET_DEFAULT_VARIABLES_ERROR'

export const UPDATE_DEFAULT_VARIABLES = 'UPDATE_DEFAULT_VARIABLES'
export const UPDATE_DEFAULT_VARIABLES_REQUEST = 'UPDATE_DEFAULT_VARIABLES_REQUEST'
export const UPDATE_DEFAULT_VARIABLES_SUCCESS = 'UPDATE_DEFAULT_VARIABLES_SUCCESS'
export const UPDATE_DEFAULT_VARIABLES_ERROR = 'UPDATE_DEFAULT_VARIABLES_ERROR'

export const UPDATE_REGISTRATION_MESSAGES = 'UPDATE_REGISTRATION_MESSAGES'
export const UPDATE_REGISTRATION_MESSAGES_REQUEST = 'UPDATE_REGISTRATION_MESSAGES_REQUEST'
export const UPDATE_REGISTRATION_MESSAGES_SUCCESS = 'UPDATE_REGISTRATION_MESSAGES_SUCCESS'
export const UPDATE_REGISTRATION_MESSAGES_ERROR = 'UPDATE_REGISTRATION_MESSAGES_ERROR'

export const GET_REGISTRATION_MESSAGES = 'GET_REGISTRATION_MESSAGES'
export const GET_REGISTRATION_MESSAGES_REQUEST = 'GET_REGISTRATION_MESSAGES_REQUEST'
export const GET_REGISTRATION_MESSAGES_SUCCESS = 'GET_REGISTRATION_MESSAGES_SUCCESS'
export const GET_REGISTRATION_MESSAGES_ERROR = 'GET_REGISTRATION_MESSAGES_ERROR'
