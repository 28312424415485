import { AccountTypeIdEnum, ItemsListNamesEnum } from '@medentee/enums'

export const API = {
  LOGOUT: '/logout',
  LOGIN: '/auth/login',
  CHANGE_PASSWORD: '/auth/password',
  PROTECTED: '/accounts/protected',
  WHO_AMI: '/account/whoami',
  ADD_ADMIN: '/management/admin',
  USER_PROFILE: (id: string) => `/accounts/${id}`,
  USER_PROFILE_BALANCE: (id: string) => `/management/operations/history/${id}`,
  UPDATE_DEFAULT_VARIABLES: '/variables',
  REGISTRATION_MESSAGES: '/variables/registration-messages',
  DEFAULT_VARIABLES: '/variables/all',
  MDT_BALANCE_DETAILS: '/management/operations/header',
  GENERATE_MDT: '/management/tokens',
  AIRDROP: '/management/airdrop',
  MDT_BALANCE_HISTORY: '/management/operations/history',
  USERS: '/management/users',
  PERSONAL_ACCOUNTS: '/management/users/personal',
  PERSONAL_ACCOUNTS_DETAILS: '/management/users/personal/total',
  BUSINESS_ACCOUNTS: '/management/users/business',
  BUSINESS_ACCOUNTS_DETAILS: '/management/users/business/total',
  INVITE_NEW_USER: '/user-invites/user',
  DELETE_ACCOUNT: (id: string) => `/accounts/${id}`,
  SUBSCRIPTION_PLAN: (accountTypeId: AccountTypeIdEnum) =>
    `/management/subscriptions/${accountTypeId}`,
  LIST_ITEMS: (type: ItemsListNamesEnum) => `/list-items/${type}`
}

export const GENERAL_API = {
  PROFESSIONS: '/misc/professions/v2'
}
