import React, { ReactElement, useCallback } from 'react'
import { Button, Card, Form, Row } from 'antd'

import { createProcessingSelector } from 'store'
import { useAppSelector } from 'App'
import { useDialog } from 'App/hooks/useDialog'

import { EditSwitcher } from '../EditSwitcher'

import styles from './DefaultFormCard.module.scss'

export type THandleFinish<T = any> = (values: T) => void

export type TDefaultFormCardProps<T> = {
  title: string
  initialValues: T
  loadingActionType: string
  onFormSubmit: THandleFinish<T>
  savedValues?: any
  children: (disabled: boolean) => ReactElement | ReactElement[]

  controllable?: boolean
}

export const DefaultFormCard = <T extends object>({
  title,
  initialValues,
  loadingActionType,
  onFormSubmit,
  savedValues,
  children,
  controllable = true
}: TDefaultFormCardProps<T>) => {
  const loading = useAppSelector(createProcessingSelector([loadingActionType]))
  const { state: isEditing, toggle } = useDialog()
  const [form] = Form.useForm()

  const handleFinish = useCallback<THandleFinish>(
    (values) => {
      onFormSubmit(values)
    },
    [onFormSubmit]
  )
  const handleCancel = () => {
    toggle()
    if (savedValues) {
      form.setFieldsValue(savedValues)
    }
  }
  return (
    <Card
      title={title}
      extra={
        controllable ? (
          <EditSwitcher editing={isEditing} onClick={isEditing ? handleCancel : toggle} />
        ) : null
      }
      bordered={false}
    >
      <Form
        form={form}
        className={styles.form}
        initialValues={initialValues}
        onFinish={handleFinish}
        layout="vertical"
      >
        <div className={styles.content}>{children(!isEditing)}</div>
        {isEditing && (
          <Row justify="center">
            <Button htmlType="submit" type="primary" loading={loading}>
              Save
            </Button>
          </Row>
        )}
      </Form>
    </Card>
  )
}
