import React, { useEffect, useState } from 'react'
import { Form, Input, Row, Col } from 'antd'

import { useAppSelector } from 'App'
import { DefaultFormCard } from 'App/common'
import { UPDATE_REGISTRATION_MESSAGES } from 'store'

import { TCommonDefaultVariablesProps } from '../../'

export const DefaultVariablesWelcomeMessage = ({ onFormSubmit }: TCommonDefaultVariablesProps) => {
  const defaultVariables = useAppSelector((state) => state.defaultVariables)

  const initialValues = {
    WELCOME_MESSAGE_COMMUNITY:
      defaultVariables.registrationMessages?.community || 'Welcome to Medentee!',
    WELCOME_MESSAGE_EVENT: defaultVariables.registrationMessages?.event || 'Welcome to Medentee!',
    WELCOME_MESSAGE_CONTACTS:
      defaultVariables.registrationMessages?.contact || 'Welcome to Medentee!',
    WELCOME_MESSAGE_ORGANIZATION:
      defaultVariables.registrationMessages?.staff || 'Welcome to Medentee!'
  }

  const [savedValues, setSavedValues] = useState(initialValues)

  useEffect(() => {
    setSavedValues(initialValues)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultVariables])

  return (
    <DefaultFormCard
      title="Welcome Message"
      onFormSubmit={onFormSubmit}
      savedValues={savedValues}
      loadingActionType={UPDATE_REGISTRATION_MESSAGES}
      initialValues={savedValues}
    >
      {(disabled) => (
        <Row gutter={[20, 20]}>
          <Col xs={24} md={12}>
            <Form.Item
              name="WELCOME_MESSAGE_COMMUNITY"
              label="Community Invitation"
              rules={[{ required: true, message: 'This field is required' }]}
            >
              <Input.TextArea maxLength={3000} rows={3} disabled={disabled} />
            </Form.Item>
          </Col>
          <Col xs={24} md={12}>
            <Form.Item
              name="WELCOME_MESSAGE_EVENT"
              label="Event Invitation"
              rules={[{ required: true, message: 'This field is required' }]}
            >
              <Input.TextArea maxLength={3000} rows={3} disabled={disabled} />
            </Form.Item>
          </Col>
          <Col xs={24} md={12}>
            <Form.Item
              name="WELCOME_MESSAGE_CONTACTS"
              label="Contacts Invitation"
              rules={[{ required: true, message: 'This field is required' }]}
            >
              <Input.TextArea maxLength={3000} rows={3} disabled={disabled} />
            </Form.Item>
          </Col>
          <Col xs={24} md={12}>
            <Form.Item
              name="WELCOME_MESSAGE_ORGANIZATION"
              label="Organization Invitation"
              rules={[{ required: true, message: 'This field is required' }]}
            >
              <Input.TextArea maxLength={3000} rows={3} disabled={disabled} />
            </Form.Item>
          </Col>
        </Row>
      )}
    </DefaultFormCard>
  )
}
